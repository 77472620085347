import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import Layout from '../components/layout';
import { SVGShapeHeader } from '../components/svgs';
// import Image from '../components/image';
import SEO from '../components/seo';
import { SectionHeader, Title } from '../components/styles/Title';
import FlexGrid from '../components/FlexGrid';
import { ProjectCard } from '../components/ProjectCard';

export const Container = styled.div`
  width: 100%;
  background: var(--gray-1);
`;

export const MaxWidthContainer = styled.div`
  position: relative;
  max-width: 1100px;
  padding: 0 2rem;
  margin: 0 auto;
`;

export const HeaderSVG = styled.div`
  position: relative;
  width: 100%;
  height: 140px;
  background: var(--orange-blue-gradient-left-bottom);
`;

export const SVGShapeContainer = styled.div`
  width: 100%;
  overflow: hidden;
  display: block;
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: 160px;
  transform: translateY(1px);
  color: var(--gray-1);

  svg {
    box-sizing: border-box;
    position: absolute;
    left: -3%;
    right: -3%;
    bottom: 0px;
    width: 106%;
    height: 80px;
  }
`;

const NumberOfProjects = styled(SectionHeader)`
  display: flex;
  justify-content: flex-end;
`;

export const HeaderContainer = styled.div`
  padding: 0 1rem;
`;

const ProjectsPage = ({ data }) => {
  const { edges: folioItems } = data.allMarkdownRemark;

  return (
    <Layout>
      <SEO title='Projects' />
      <Container>
        <HeaderSVG>
          <SVGShapeContainer>
            <SVGShapeHeader />
          </SVGShapeContainer>
        </HeaderSVG>
        <MaxWidthContainer>
          <HeaderContainer>
            <Title>Projects</Title>
            <NumberOfProjects>{folioItems.length} Projects</NumberOfProjects>
          </HeaderContainer>
          <FlexGrid>
            {folioItems.map(({ node: { frontmatter: { url, cover, title, github, tech }, html } }) => (
              <ProjectCard key={title} {...{ url, cover, title, github, tech, description: html }} />
            ))}
          </FlexGrid>
        </MaxWidthContainer>
      </Container>
    </Layout>
  );
};

export default ProjectsPage;

export const projectsPageQuery = graphql`
  query projectsPageQuery {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/projects/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          html
          frontmatter {
            title
            url
            github
            tech
            cover {
              publicURL
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
